<template>
  <v-dialog v-model="dialog" max-width="800px" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        dark
        class="my-1 text-none"
        v-bind="attrs"
        v-on="on"
        rounded
      >
        <v-icon left> mdi-plus </v-icon>
        Cargos de usuario
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="d-flex flex-column">
        <span class="text-body-1 primary--text">Lista de cargos</span>
        <validation-alert class="mt-5" :validationErrors="errors" />
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form>
            <v-row>
              <!-- CHARGE -->
              <v-col cols="12" md="4">
                <v-autocomplete
                  v-model="formChargeUser.company_id"
                  :items="companies"
                  item-text="name"
                  item-value="id"
                  label="Empresa"
                  name="company_id"
                  required
                  @change="getCharges()"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="4">
                <v-autocomplete
                  v-model="formChargeUser.charge_id"
                  :items="charges"
                  item-text="name"
                  item-value="id"
                  label="Cargo"
                  required
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="4" class="d-flex justify-end align-center">
								<v-btn
									color="primary"
									class="text-none"
									rounded
                  block
									@click="addCharge()"
                  :disabled="!formChargeUser.company_id || !formChargeUser.charge_id"
                  type="button">
									<v-icon left> mdi-plus </v-icon>
									Agregar
								</v-btn>
							</v-col>
            </v-row>
          </v-form>

          <v-data-table
            :headers="headers"
            :items="chargesTable"
            :items-per-page="-1"
            :loading="isLoadingTable"
            item-key="id"
            class="elevation-1 mt-4"
            hide-default-footer
          >
            <template v-slot:[`item.company_name`]="{ item }">
              <span v-text="item.company?item.company.name:''"></span>
            </template>

            <template v-slot:[`item.charge_name`]="{ item }">
              <span v-text="item.name"></span>
            </template>
            
            <template v-slot:[`item.actions`]="{ index }">
              <v-icon class="text-md-body-1" @click="removeCharge(index)">
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey lighten-1" text @click="close()"> Cancelar </v-btn>
        <v-btn color="blue" text @click="save()" :loading="isLoadingSave" type="button"> Guardar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ValidationAlert from '@/components/global/ValidationAlert.vue';
import { $EventBus } from "@/main";

export default {
  name: "CreateChargeDialog",
  components: {
    ValidationAlert,
	},
  props: {
    value: Boolean,
  },

  data: () => ({
    isValidFormDialog: false,
    nameRules: [(v) => !!v || "Campo requerido"],
    companyRules: [(v) => !!v || "Campo requerido"],
    userNameRules: [v => (v && v.split(' ').length <= 2) || 'No se permiten espacios en blanco'],
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'E-mail no es válido'
    ],
    user: {
      id: null,
      name: "",
      last_name_father: "",
      username: "",
      email_corp: "",
      active: true,
    },
    errors: [],
    isLoadingSave: false,
    documentTypes: [
      { value: null, label: 'Ninguno' },
      { value: 'DNI', label: 'DNI' },
      { value: 'PASAPORTE', label: 'Pasaporte' },
      { value: 'CE', label: 'Carnet de extranjeria' }
    ],
    companyId: null,
    formChargeUser: {
      company_id: null,
      charge_id: null
    },
    headers: [
      {
        text: "Compañia",
        align: "start",
        sortable: false,
        value: "company_name",
        class: "primary--text",
      },
      {
        text: "Cargo",
        align: "start",
        sortable: false,
        value: "charge_name",
        class: "primary--text",
      },
      {
        text: "Acción",
        align: "right",
        sortable: false,
        value: "actions",
        class: "primary--text",
      },
    ],
    isLoadingTable: false,
  }),

  watch: {
    dialog(val) {
      val || this.close();
      if (val) {
        this.$store.dispatch("companies/getCompaniesList");
        if(this.user.company_id && this.user.company_id != undefined) {
          this.getCharges()
        }
      }
    },
    value(val){
      if(val && this.userFind){
        this.user.id = this.userFind.id;
        this.user.name = this.userFind.name;
        this.user.last_name_father = this.userFind.last_name_father;
        this.user.username = this.userFind.username;
        this.user.email_corp = this.userFind.email_corp;
        this.user.active = this.userFind.active;
        this.user.charge_id = this.userFind.charge_id;
        this.user.document_type = this.userFind.document_type;
        this.user.document = this.userFind.document;
        this.user.services = this.userFind.services;
        this.user.charge_code = this.userFind.charge_code;
        this.user.dossier = this.userFind.dossier;
        this.user.employee_number_sap = this.userFind.employee_number_sap;

        this.user.company_id = null;
        if(this.userFind.charge){
          this.user.company_id = this.userFind.charge.company_id
          this.getCharges();
        }
      }
    }
  },

  computed: {
		dialog: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
    userFind() {
      return this.$store.getters["users/getUser"];
    },
    companies() {
      return this.$store.getters["companies/getCompaniesList"];
    },
    charges() {
      let chargesAll = this.$store.getters["charges/getChargesList"];
      let charges = [];

      chargesAll.forEach(chargeItem => {
        let exist = false;
        this.chargesTable.forEach(chargeItemTable => {
          if(chargeItemTable.id == chargeItem.id && chargeItemTable.company_id == chargeItem.company_id){
            exist = true;
          }
        });

        if(!exist){
          charges.push(chargeItem);
        }
      });

      return charges;
    },
    chargesTable(){
      let charges = this.$store.getters["users/getUserCharges"];
      return charges;
    }
	},

  mounted: function () {
  },

  methods: {
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
      });
    },

    async save() {
      try {
        this.isLoadingSave = true;
        let chargeUsers = this.chargesTable.map(item => {
          return {company_id: item.company_id, charge_id: item.id};
        });
        let dataSave = {
          user_id: this.userFind.id,
          charges: chargeUsers
        };
        
        const { ok, message } = await this.$store.dispatch('users/updateCharges', dataSave);

        if (ok) {
          $EventBus.$emit("showSnack", {
            color: "green",
            text: "Operación realizada satisfactoriamente",
          });
          this.isLoadingSave = false;
          this.close();
        } 
        else {
          this.errors = message;
          this.isLoadingSave = false;
          $EventBus.$emit(
            'showSnack',
            'error',
            'Algo salio mal, no se guardo la información'
          );
        }
      } catch (error) {
				this.isLoadingSave = false;
			}
    },

    getCharges(){
      this.$store.dispatch("charges/getChargesList", {
          company_id: this.formChargeUser.company_id
      });
    },

    removeCharge(index) {
			this.$store.dispatch('users/removeUserCharge', index);
		},

    resetCompanyCharge(){
      this.formChargeUser.company_id = null;
      this.formChargeUser.charge_id = null;
      this.$store.dispatch("charges/resetChargesList");
    },

    addCharge(){
      this.charges.forEach(charge => {
        if(charge.id == this.formChargeUser.charge_id && charge.company_id == this.formChargeUser.company_id){
          this.$store.dispatch('users/addUserCharge', charge);
          this.resetCompanyCharge();
        }
      });
    }
  },
};
</script>
