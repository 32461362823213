<template>
	<v-dialog v-model="dialog" max-width="500px" persistent>
		<v-card>
			<v-card-title>
				<span class="text-body-1 primary--text mb-2">Cambiar Contraseña</span>
			</v-card-title>
			<v-card-subtitle>{{ user.fullName }}</v-card-subtitle>
			<v-card-text>
				<v-container>
					<v-form ref="form" v-model="isValidFormDialog" lazy-validation>
						<v-row>
							<v-col cols="12" sm="12" md="12">
								<v-text-field
									v-model="editedItem.password"
									:rules="passwordRules"
									label="Contraseña"
									type="password"
									required
								></v-text-field>
							</v-col>
							<v-col cols="12" sm="12" md="12">
								<v-text-field
									v-model="editedItem.confirmPassword"
									:rules="confirmPasswordRules"
									label="Confirmar Contraseña"
									type="password"
									required
								></v-text-field>
							</v-col>
						</v-row>
					</v-form>
					<validation-alert :validationErrors="errors" />
				</v-container>
			</v-card-text>

			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="grey lighten-1" text @click="close()"> Cancelar </v-btn>
				<v-btn color="blue" text :loading="isLoadingSave" @click="save()"> Guardar </v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import ValidationAlert from '@/components/global/ValidationAlert.vue';
import { $EventBus } from '@/main';
export default {
	name: 'UpdatePasswordDialog',

	props: {
		value: Boolean,
		user: {
			type: Object,
			required: false,
		},
	},

	data: () => ({
		isValidFormDialog: false,
		editedItem: {
			password: '',
			confirmPassword: '',
		},
		defaultItem: {
			password: '',
			confirmPassword: '',
		},
		passwordRules: [
			(v) => !!v || 'Campo requerido',
			(v) => (v && v.length >= 6) || 'La contraseña debe tener menos de 6 caracteres',
		],
		errors: [],
		isLoadingSave: false,
	}),

	computed: {
		dialog: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('input', value);
			},
		},

		confirmPasswordRules() {
			return [
				(v) => !!v || 'Campo requerido',
				(v) => (v && v.length >= 6) || 'La contraseña debe tener menos de 6 caracteres',
				(v) => v == this.editedItem.password || 'La contraseña no coincide',
			];
		},
	},

	watch: {
		dialog(val) {
			if (val) {
				//this.editedItem = this.user;
			} else {
				//this.close();
			}
		},
	},

	methods: {
		close() {
			this.dialog = false;
			this.editedItem = { ...this.defaultItem };
			this.$nextTick(() => {
				this.$refs.form.resetValidation();
			});
		},

		async save() {
			if (this.$refs.form.validate()) {
				try {
					this.isLoadingSave = true;
					const formData = {
						user_id: this.user.id,
						password: this.editedItem.password,
						confirm_password: this.editedItem.confirmPassword,
					};
					const { ok, message } = await this.$store.dispatch('users/updatePassword', formData);
					if (ok) {
						$EventBus.$emit('showSnack', {
							color: 'green',
							text: 'Se ha actualizado satisfactoriamente',
						});
						this.close();
					} else {
						this.errors = message;
					}
					this.isLoadingSave = false;
				} catch (error) {
					this.isLoadingSave = false;
				}
			}
		},
	},
	components: {
		ValidationAlert,
	},
};
</script>
