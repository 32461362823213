<template>
  <v-dialog v-model="dialog" max-width="500px" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        dark
        class="my-1 text-none"
        v-bind="attrs"
        v-on="on"
        rounded
      >
        <v-icon left> mdi-plus </v-icon>
        Nuevo Usuario
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="d-flex flex-column">
        <span class="text-body-1 primary--text">Nuevo Usuario</span>
        <validation-alert class="mt-5" :validationErrors="errors" />
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="isValidFormDialog" lazy-validation>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="user.name"
                  :rules="nameRules"
                  label="Nombres"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="user.last_name_father"
                  :rules="nameRules"
                  label="Apellidos"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="user.email_corp"
                  :rules="emailRules"
                  label="Correo electrónico"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  v-model="user.username"
                  :rules="userNameRules"
                  label="Nombre de usuario"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-switch v-model="user.active" label="Activo"></v-switch>
              </v-col>
              <!-- CHARGE -->
              <!-- <v-col cols="12" md="6">
                <v-autocomplete
                  v-model="user.company_id"
                  :items="companies"
                  item-text="name"
                  item-value="id"
                  label="Empresa"
                  required
                  @change="getCharges()"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6">
                <v-autocomplete
                  v-model="user.charge_id"
                  :items="charges"
                  item-text="name"
                  item-value="id"
                  label="Cargo"
                  required
                ></v-autocomplete>
              </v-col> -->
              <!-- <v-col cols="12" sm="12" md="6">
                <v-text-field
                  v-model="user.charge"
                  label="Cargo"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  v-model="user.charge_code"
                  label="Código de cargo"
                ></v-text-field>
              </v-col> -->
              <v-col cols="12" sm="12" md="6">
                <v-select
                  v-model="user.document_type"
                  :items="documentTypes"
                  item-text="label"
                  item-value="value"
                  label="Tipo de documento"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  v-model="user.document"
                  label="Documento de identidad"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  v-model="user.dossier"
                  label="Legajo"
                ></v-text-field>
              </v-col>
              <!-- <v-col cols="12" sm="12" md="6">
                <v-text-field
                  v-model="user.employee_number_sap"
                  label="Código de Proveedor"
                ></v-text-field>
              </v-col> -->
              <v-col cols="12" md="6">
                <v-switch v-model="user.flag_provider" label="Proveedor"></v-switch>
              </v-col>
              <!-- <v-col cols="12" sm="12" md="12">
                <v-textarea
                  v-model="user.services"
                  label="Servicios"
                ></v-textarea>
              </v-col> -->
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  v-model="user.division"
                  label="Division"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  v-model="user.management"
                  label="Gerencia"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  v-model="user.service"
                  label="Servicio"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  v-model="user.area"
                  label="Area"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  v-model="user.job_title"
                  label="Posicion"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  v-model="user.job_code"
                  label="Codigo"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  v-model="user.country"
                  label="Pais"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  v-model="user.gender"
                  label="Genero"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  v-model="user.hire_date"
                  label="Fecha de inicio en la compañia"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  v-model="user.last_modified"
                  label="Ultima fecha de modificacion"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey lighten-1" text @click="close()"> Cancelar </v-btn>
        <v-btn color="blue" text @click="save()" :loading="isLoadingSave"> Guardar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ValidationAlert from '@/components/global/ValidationAlert.vue';

import { $EventBus } from "@/main";
export default {
  name: "CreateUserDialog",
  components: {
    ValidationAlert,
	},
  props: {
    value: Boolean,
  },

  data: () => ({
    isValidFormDialog: false,
    nameRules: [(v) => !!v || "Campo requerido"],
    companyRules: [(v) => !!v || "Campo requerido"],
    userNameRules: [v => (v && v.split(' ').length <= 2) || 'No se permiten espacios en blanco'],
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'E-mail no es válido'
    ],
    user: {
      id: null,
      name: "",
      last_name_father: "",
      username: "",
      email_corp: "",
      active: true,
      flag_provider:false
    },
    errors: [],
    isLoadingSave: false,
    documentTypes: [
      { value: null, label: 'Ninguno' },
      { value: 'DNI', label: 'DNI' },
      { value: 'PASAPORTE', label: 'Pasaporte' },
      { value: 'CE', label: 'Carnet de extranjeria' }
    ],
    companyId: null
  }),

  watch: {
    dialog(val) {
      val || this.close();
      if (val) {
        this.$store.dispatch("companies/getCompaniesList");
        if(this.user.company_id && this.user.company_id != undefined) {
          this.getCharges()
        }
      }
    },
    value(val){
      if(val && this.userFind){
        this.user.id = this.userFind.id;
        this.user.name = this.userFind.name;
        this.user.last_name_father = this.userFind.last_name_father;
        this.user.username = this.userFind.username;
        this.user.email_corp = this.userFind.email_corp;
        this.user.active = this.userFind.active;
        this.user.flag_provider = this.userFind.flag_provider;
        // this.user.charge_id = this.userFind.charge_id;
        this.user.document_type = this.userFind.document_type;
        this.user.document = this.userFind.document;
        this.user.services = this.userFind.services;
        // this.user.charge_code = this.userFind.charge_code;
        this.user.dossier = this.userFind.dossier;
        this.user.employee_number_sap = this.userFind.employee_number_sap;
        this.user.division = this.userFind.division;        
        this.user.management = this.userFind.management;        
        this.user.service = this.userFind.service;        
        this.user.area = this.userFind.area;        
        this.user.job_title = this.userFind.job_title;        
        this.user.job_code = this.userFind.job_code;        
        this.user.country = this.userFind.country;        
        this.user.gender = this.userFind.gender;        
        this.user.hire_date = this.userFind.hire_date;        
        this.user.last_modified = this.userFind.last_modified;        

        this.user.company_id = null;
        if(this.userFind.charge){
          this.user.company_id = this.userFind.charge.company_id
          this.getCharges();
        }
      }
    }
  },

  computed: {
		dialog: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
    userFind() {
      return this.$store.getters["users/getUser"];
    },
    companies() {
      return this.$store.getters["companies/getCompaniesList"];
    },
    charges() {
      return this.$store.getters["charges/getChargesList"];
    }
	},


  methods: {
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.$refs.form.reset();
      });
    },

    async save() {
      if (this.$refs.form.validate()) {
        try {
          this.isLoadingSave = true;
          this.errors = [];
          let action = this.user.id?'updateUser':'saveUser';
          const { ok, message } = await this.$store.dispatch('users/'+action, this.user);
            if (ok) {
              $EventBus.$emit("showSnack", {
                color: "green",
                text: "Operación realizada satisfactoriamente",
              });
              this.isLoadingSave = false;
              this.$store.dispatch("users/getUsers", { page: 1 });
              this.close();
            } else {
              this.errors = message;
              this.isLoadingSave = false;
              $EventBus.$emit(
                'showSnack',
                'error',
                'Algo salio mal, no se guardo la información'
              );
            }
        } catch (error) {
					this.isLoadingSave = false;
				}
      }
    },

    getCharges(){
      this.$store.dispatch("charges/getChargesList", {
          company_id: this.user.company_id
      });
    }
  },
};
</script>
