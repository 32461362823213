import UpdatePasswordDialog from "@/components/users/user/UpdatePasswordDialog.vue";
import CreateUserDialog from "@/components/users/user/CreateUserDialog.vue";
import createChargesDialog from "@/components/users/charge/CreateChargeDialog.vue";
import SearchInput from "@/components/global/SearchInput.vue";
import { usersTableHeaders } from "@/assets/js/tableHeader.js";
import { $EventBus } from "@/main";
import { mapActions } from "vuex";

export default {
  name: "Users",

  data: () => ({
    filter: {
      search: "",
      active: null,
      searchType: 'list',
      pagination: true,
      itemsPerPage: 10,
      currentPage: 1
    },
    searchTimeout: null,
    searchValue: '',
    headers: usersTableHeaders,
    editedItem: {},
    deletedItem: {},
    currentPage: 1,
    PasswordDialog: false,
    createUserDialog: false,
    createChargesDialog: false,
    errors: [],
    statusList: [
      { value: null, name: 'Todos los estados' },
      { value: true, name: 'Activos' },
      { value: false, name: 'No activos' }
    ]
  }),

  computed: {
    users() {
      return this.$store.getters["users/getUsers"];
    },
    paginationPages() {
      return this.$store.getters["users/getPaginationPages"];
    },
    userPagination() {
      return this.$store.getters["users/getPaginationUser"];
    },
    isLoading() {
      return this.$store.state.users.isLoading;
    },
    user() {
      return this.$store.getters["users/getUser"];
    },
  },

  created() {
    // this.$store.dispatch("users/getUsers", { page: 1 });
    this.getUsers();
  },

  methods: {
    ...mapActions('users', ['softDeleteUser', 'searchGetUsers']),

    async getUsers() {
      // this.isLoading = true;
      await this.searchGetUsers(this.filter)
      // this.isLoading = false;
    },

    editItem(item) {
      this.editedItem = { ...item };
    },

    deleteItem(item) {
      this.deletedItem = { ...item };
    },

    listenPagination() {
      this.$store.dispatch("users/searchGetUsers", this.filter);
    },

    debounceSearch() {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
          const searchValue = this.searchValue || '';
          const trimmedSearchValue = searchValue.trim();

          if (searchValue === '') {

              this.filter.search = '';
              this.filter.currentPage = 1;
              this.listenPagination();
              return;
          }

          if (trimmedSearchValue === '') {
              return;
          }

          this.filter.search = trimmedSearchValue;
          this.filter.currentPage = 1;
          this.listenPagination();
      }, 800);
  },

    listenChangueSearch() {
      this.filter.page = 1;
    },

    openPasswordDialog(user) {
      this.editedItem = user;
      this.PasswordDialog = true;
    },
    openCreateUserDialog() {
      this.$store.dispatch('users/clearUser');
      this.createUserDialog = true;
    },

    async changeActive(id, e) {
      try {
        const { ok } = await this.$store.dispatch('users/updateActive', { id: id, active: e });
        if (ok) {
          this.$store.dispatch("users/getUsers", { page: 1 });
          $EventBus.$emit("showSnack", {
            color: "green",
            text: "Se ha actualizado satisfactoriamente",
          });
        } else {
          $EventBus.$emit(
            'showSnack',
            'error',
            'Algo salio mal, no se actualizo la información'
          );
        }
      } catch (error) {
      }
    },

    async editUser(id) {
      this.$store.dispatch('users/clearUser');
      await this.$store.dispatch('users/getUser', { user_id: id });
      if (this.user) {
        this.createUserDialog = true;
      }
    },

    async editCharges(id) {
      await this.$store.dispatch('users/getUser', { user_id: id });
      await this.$store.dispatch("users/getUserCharges", { user_id: id });
      if (this.user) {
        this.createChargesDialog = true;
      }
    },

    async deleteUser(id) {
      this.$swal({
        text: '¿Estás seguro de eliminar este usuario?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#0039a6',
        cancelButtonColor: 'grey',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Sí',
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            console.log(id)
            const { error } = await this.softDeleteUser(id);

            if (error) this.showSnack('warning', error);

            this.$store.dispatch("users/getUsers", { page: 1 });

          } catch (error) {
            console.log(error)
          }
        }
      });
    }
  },

  destroyed() {
    clearTimeout(this.timeout);
  },
  components: {
    SearchInput,
    UpdatePasswordDialog,
    CreateUserDialog,
    createChargesDialog
  },
};
